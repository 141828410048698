/* Create a global app namespace */
window.App || (window.App = { });

App.init = function () {
    /* put global init code here */

    App.loadAppData();
    App.registerThingFormToggler();
    App.selectGatewayEnvironment();
    App.setupEditSiteForm();
    App.initCharts();
    App.openAnnounceGwModal();
    App.initClipboardCopy();
    App.initSecretToggle();
    App.registerGatewayStatusIndicators();
    App.toggleGatewayActivationForm();
    App.conditionalFormElements();
    App.initActivationTokenInput();

}

$(document).on('ready turbolinks:load', function() {
    App.init()
});

$(document).on('turbolinks:before-render', function() {
    if (App.refreshGatewayStatusIndicatorsTimer) {
        clearTimeout(App.refreshGatewayStatusIndicatorsTimer)
    }
});
